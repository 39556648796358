
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.product-configurator-editor-color {
  margin: 3px 0 3px 3px;
}
