
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$productconfigurator-summary-group-grid-column-gap: 0 !default;
$productconfigurator-summary-group-grid-row-gap: $spacer !default;
$productconfigurator-summary-group-grid-columns: auto auto min-content !default;

$productconfigurator-summary-group-separator-gap: $spacer !default;
$productconfigurator-summary-group-separator-border: map-get($borders, 'base') !default;

$productconfigurator-summary-group-title-font-size: $font-size-lg !default;
$productconfigurator-summary-group-title-font-weight: $font-weight-medium !default;
$productconfigurator-summary-group-title-color: inherit !default;

$productconfigurator-summary-group-item-value-padding-x: $spacer !default;
$productconfigurator-summary-group-item-edit-color: $primary !default;

$productconfigurator-editor-bg: $white !default;
$productconfigurator-editor-transition: left 200ms ease-out !default;
$productconfigurator-editor-muchdata-min-height: 400px !default;
$productconfigurator-editor-min-height: 200px !default;

$productconfigurator-editor-title-gap: $spacer !default;
$productconfigurator-editor-title-font-size: inherit !default;
$productconfigurator-editor-title-font-weight: $font-weight-bold !default;
$productconfigurator-editor-title-color: inherit !default;

$productconfigurator-editor-content-description-gap: $spacer !default;

.variant-selector-container {
  margin-top: 0;
  padding-top: $spacer;
  border-top: $productconfigurator-summary-group-separator-border;

  .variant-selector {
    list-style: none;
    margin: 0;
    padding: 0;

    .variant-selector-item {
      margin-bottom: $spacer;
      padding-bottom: $spacer;
      border-bottom: $productconfigurator-summary-group-separator-border;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      cursor: pointer;
      position: relative;

      .modell-title {
        text-transform: uppercase;
      }

      img {
        width: 100%;
        height: 100%;
        max-width: 6em;
        flex-shrink: 0;
      }

      .check-icon {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
      }

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.product-configurator {
  position: relative;
  overflow: hidden;
  min-height: $productconfigurator-editor-min-height;
  margin-bottom: $spacer;

  .configurator-summary {
    .summary-group {
      display: grid;
      column-gap: $productconfigurator-summary-group-grid-column-gap;
      row-gap: $productconfigurator-summary-group-grid-row-gap;
      grid-template-columns: $productconfigurator-summary-group-grid-columns;
      margin-top: $productconfigurator-summary-group-separator-gap;
      padding-top: $productconfigurator-summary-group-separator-gap;
      border-top: $productconfigurator-summary-group-separator-border;

      align-items: center;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
        border-top: 0 none;
      }

      .group-title {
        grid-column: 1 / 4;
        font-size: $productconfigurator-summary-group-title-font-size;
        font-weight: $productconfigurator-summary-group-title-font-weight;
        color: $productconfigurator-summary-group-title-color;
      }

      .group-item {
        display: contents;

        .item-value {
          padding: 0 $productconfigurator-summary-group-item-value-padding-x;
        }

        .item-edit {
          color: $productconfigurator-summary-group-item-edit-color;
        }
      }
    }
  }

  .configurator-editor {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: $productconfigurator-editor-bg;

    .editor-title {
      flex: 0 0 auto;
      margin-bottom: $productconfigurator-editor-title-gap;
      font-size: $productconfigurator-editor-title-font-size;
      font-weight: $productconfigurator-editor-title-font-weight;
      color: $productconfigurator-editor-title-color;

      a {
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        text-decoration: none;
      }
    }

    .editor-content-wrapper {
      flex: 0 1 100%;
      overflow: hidden;

      .editor-content {
        .content-description {
          margin-top: $productconfigurator-editor-content-description-gap;
        }
      }

      .hasVBar {
        .editor-content {
          margin-right: 12px;
        }
      }
    }

    &.editor-enter,
    &.editor-leave-to {
      left: 100%;
    }

    &.editor-enter-active,
    &.editor-leave-active {
      overflow: hidden;
      transition: $productconfigurator-editor-transition;
    }

    &.editor-enter-to,
    &.editor-leave {
      left: 0%;
    }
  }

  &.editor-open {
    &.muchdata {
      min-height: $productconfigurator-editor-muchdata-min-height;
    }
  }
}
